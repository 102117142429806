//数据字典接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-10 13:15:50
 */
// 字典分页查询
export function dictPageList(parameter){
    return axios({
        url: '/dict/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//字典菜单树
export function dictTree(parameter){
    return axios({
        url: '/dict/tree',
        method: 'post',
        data: parameter
    })
}
//字典新增
export function dictAdd(parameter){
    return axios({
        url: '/dict/add',
        method: 'post',
        data: parameter 
    })
}
//字典编辑
export function dictEdit(parameter){
    return axios({
        url: '/dict/edit',
        method: 'post',
        data: parameter
    })
}
//直播分类详情
export function dictDetail(parameter){
    return axios({
        url: '/dict/detail',
        method: 'post',
        params: parameter
    })
}
//字典删除
export function dictDelete(parameter){
    return axios({
        url: '/dict/delete',
        method: 'post',
        params: parameter
    })
}
//直播分类list集合
export function dictList(parameter){
    return axios({
        url: '/dict/list',
        method: 'post',
        data: parameter
    })
}